<template>
  <div>
    <WebDocumentViewer :id=$route.params.Id :typeReport=$route.params.jenisReport :idUser=$route.params.idUser />
  </div>
</template>

<script>
import WebDocumentViewer from '@/views/pages/ReportComponent.vue'

export default {
  name: 'app',
  components: {
    WebDocumentViewer
  },
  mounted() {
    // console.log('data ', this.$route.params)
  }
}
</script>
