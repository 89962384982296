<template>
  <div ref="viewer" style="position: absolute; left: 0; right: 0; top: 0; bottom: 0" data-bind="dxReportViewer: $data"></div>
</template>

<script>
import ko from 'knockout'
import {
  ActionId,
  PreviewElements
} from 'devexpress-reporting/dx-webdocumentviewer'

import 'jquery-ui/themes/base/all.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.css'
import '@devexpress/analytics-core/dist/css/dx-analytics.common.css'
import '@devexpress/analytics-core/dist/css/dx-analytics.light.css'
import 'devexpress-reporting/dist/css/dx-webdocumentviewer.css'
import {
  reportUseCase
} from '@/domain/usecase'
import moment from 'moment'
import {
  encryptDecriptMethods
} from '@/plugins/encryptDecriptMethods'
import { environment } from '@/utils'

export default {
  name: 'WebDocumentViewer',
  props: {
    id: {
      type: String,
      default: () => ''
    },
    typeReport: {
      type: String,
      default: () => ''
    },
    idUser: {
      type: String,
      default: () => ''
    }
  },
  mounted() {
    // console.log('ini router nya', this.$route)
    // this.isMobile()
    let observableUrl = ''
    let decryptedData
    let decryptedDataUser
    if (this.$route.name === 'report-khusus-aziz') {
      decryptedData = this.id
    } else {
      decryptedData = encryptDecriptMethods.decrypt(this.id)
      if (this.idUser) {
        decryptedDataUser = encryptDecriptMethods.decrypt(this.idUser)
      }
    }
    // console.log('decrypt', decryptedData)
    // console.log('type', this.typeReport)
    if (this.typeReport === 'resi') {
      observableUrl = `Resi?IdPengiriman=${decryptedData}&IdUser=${decryptedDataUser}`
      // this.getData(this.id)
    } else if (this.typeReport === 'manifest') {
      observableUrl = `Manifest?mnfstid=${decryptedData}`
    } else if (this.typeReport === 'advance-report' || this.typeReport === 'redeem-souvenir') {
      observableUrl = decryptedData
    }

    // console.log(observableUrl)
    // console.log(environment.getApiReceipt())
    const viewerOptions = {
      // isMobile: this.isMobile(),
      reportUrl: ko.observable(`${observableUrl}`), // The URL of a report.
      requestOptions: {
        host: this.isResiOrManifest() ? environment.getApiReceipt() : environment.getApiReporting(),
        // host: environment.getApiReceipt(),
        // host: 'https://localhost:44368/',
        invokeAction: 'DXXRDV'
      },
      callbacks: {
        CustomizeElements: (s, e) => {
          const $isResiOrManifest = this.isResiOrManifest()
          if ($isResiOrManifest) {
            const toolbarPart = e.GetById(PreviewElements.Toolbar)
            const indexToolbar = e.Elements.indexOf(toolbarPart)
            // e.Elements.splice(indexToolbar, 1)
          }
          if ($isResiOrManifest) {
            const RightPanel = e.GetById(PreviewElements.RightPanel)
            const indexRightPanel = e.Elements.indexOf(RightPanel)
            e.Elements.splice(indexRightPanel, 1)
          }
        },
        CustomizeMenuActions: (s, e) => {
          const $isResiOrManifest = this.isResiOrManifest()
          const actionPrevPage = e.GetById(
            ActionId.PrevPage
          )
          if (actionPrevPage && $isResiOrManifest) actionPrevPage.visible = false
          const actionNextPage = e.GetById(
            ActionId.NextPage
          )
          if (actionNextPage && $isResiOrManifest) actionNextPage.visible = false
          const pagination = e.GetById(
            ActionId.Pagination
          )
          if (pagination && $isResiOrManifest) pagination.visible = false
          const FirstPage = e.GetById(
            ActionId.FirstPage
          )
          if (FirstPage && $isResiOrManifest) FirstPage.visible = false
          const LastPage = e.GetById(
            ActionId.LastPage
          )
          if (LastPage && $isResiOrManifest) LastPage.visible = false
          const MultipageToggle = e.GetById(
            ActionId.MultipageToggle
          )
          if (MultipageToggle && $isResiOrManifest) MultipageToggle.visible = false
          const HighlightEditingFields = e.GetById(
            ActionId.HighlightEditingFields
          )
          if (HighlightEditingFields && $isResiOrManifest) HighlightEditingFields.visible = false
          const ZoomSelector = e.GetById(
            ActionId.ZoomSelector
          )
          if (ZoomSelector && $isResiOrManifest) ZoomSelector.visible = false
          const ZoomIn = e.GetById(
            ActionId.ZoomIn
          )
          if (ZoomIn && $isResiOrManifest) ZoomIn.visible = false
          const ZoomOut = e.GetById(
            ActionId.ZoomOut
          )
          if (ZoomOut && $isResiOrManifest) ZoomOut.visible = false
          const Search = e.GetById(
            ActionId.Search
          )
          if (Search && $isResiOrManifest) Search.visible = false
          const FullScreen = e.GetById(
            ActionId.FullScreen
          )
          if (FullScreen && $isResiOrManifest) FullScreen.visible = false
          const ExportTo = e.GetById(
            ActionId.ExportTo
          )
          if (ExportTo && $isResiOrManifest) ExportTo.visible = false
          const PrintPage = e.GetById(
            ActionId.PrintPage
          )
          if (PrintPage && $isResiOrManifest) PrintPage.visible = false

          // const actionExportTo = e.GetById(ActionId.ExportTo)
          // actionExportTo.clickAction = (arg) => {
          //   console.log('arg.itemData.format', arg.itemData.format)
          //   if (arg.itemData.format === 'csv' || arg.itemData.format === 'txt') {
          //   // my custom export
          //   } else if (arg.itemData.format === 'pdf') {
          //     s.ExportTo(arg.itemData.format)
          //   }
          // }

          const selected = ko.observable(false)
          e.Actions.push({
            text: 'Download as PDF',
            imageTemplateName: 'pdf-inline',
            visible: true,
            disabled: false,
            selected,
            hasSeparator: false,
            hotKey: {
              ctrlKey: true,
              keyCode: 'Z'.charCodeAt(0)
            },
            clickAction: () => {
              s.ExportTo('pdf')
            }
          })
          e.Actions.push({
            text: 'Download as Excel',
            imageTemplateName: 'excel-inline',
            visible: true,
            disabled: false,
            selected,
            hasSeparator: false,
            hotKey: {
              ctrlKey: true,
              keyCode: 'X'.charCodeAt(0)
            },
            clickAction: () => {
              s.ExportTo('xlsx')
            }
          })
        }
      }
    }
    ko.applyBindings(viewerOptions, this.$refs.viewer)
  },
  methods: {
    isMobile() {
      /* eslint-disable no-else-return */
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    async getData(val) {
      // console.log('masukk function get data')
      const response = await reportUseCase.getPdfResi(val)
      // console.log('reponse =>', response)
      const fileURL = window.URL.createObjectURL(new Blob([response.result]))
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      const name = `Resi${val}(${moment().format('MM-DD-YY')}).pdf`
      fileLink.setAttribute('download', name)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    isResiOrManifest() {
      return this.typeReport === 'manifest' || this.typeReport === 'resi'
    },
  },
  beforeUnmount() {
    ko.cleanNode(this.$refs.viewer)
  }
}
</script>

<style scoped>
</style>
